<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <h1>Booking Info</h1>
  <BookingInfo
    :save="!isClosed"
    :booking="booking"
    :projects="projects"
    @submitted="saveBooking"
    :canEditBookingTime="canEditBookingTime"
  />
  <h2>Testing Location</h2>
  <LocationInfo
    :save="!isClosed"
    :locations="locations"
    :location="booking.location"
    @submitted="saveLocation"
    @locations="getLocations"
  />
  <h2>Lab</h2>
  <AssignLab :save="!isClosed" @submitted="saveLab" :booking="booking" />
  <h2>Collectors</h2>
  <AssignTesters
    :save="canEditBookingTime"
    @submitted="saveTesters"
    :booking="booking"
  />
  <h2>Patients</h2>
  <UpdateTestees
    :save="!isClosed"
    @save="saveTestees"
    @add="addTestee"
    @edit="editTestee"
    :booking="booking"
    :disabled="disableForm"
    :testees="testees"
    @testees="getTestees"
  />
  <p v-if="booking.id">
    <Button
      class="p-mr-3 p-mt-3"
      @click="$router.push({ name: 'RequestedBookings' })"
      label="Done"
    />
    <SplitButton
      v-if="showReportLink"
      label="Report"
      icon="pi pi-download"
      :model="reportItems"
      class="p-mr-3 p-mt-3"
      @click="reportClick('booking')"
    />
    <Button
      v-if="booking.status === 'Results Complete' && canCloseBooking"
      @click="displayCompleteBooking = true"
      class="p-mr-3 p-mt-3 p-button-success"
      label="Complete Booking"
      icon="pi pi-check"
    />
    <Button
      v-if="booking.status === 'Ready' && canConfirmBooking"
      @click="displayConfirmBooking = true"
      class="p-mr-3 p-mt-3 p-button-success"
      label="Confirm Booking"
      icon="pi pi-check"
    />
    <Button
      v-if="canDeleteBooking"
      icon="pi pi-times"
      class="p-mt-3 p-button-danger"
      label="Delete Booking"
      @click="deleteDocument($route.params.id)"
    />
  </p>
  <ConfirmDialog
    :display="displayConfirmBooking"
    :disable="disableConfirmBooking"
    @confirm="confirmBooking"
    @display="toggleConfirmBooking"
    :attention="
      booking.conflict
        ? 'This date and time conflicts with another booking!'
        : ''
    "
    message="Are you sure you want to confirm the booking?"
    note="Notifications will be sent to Patients, Collectors and Client Admins."
  />
  <ConfirmDialog
    :comment="true"
    :display="displayCompleteBooking"
    :disable="disableCompleteBooking"
    @confirm="completeBooking"
    @display="toggleCompleteBooking"
    message="Are you sure you want to complete the booking?"
    note="A summary of results will be sent to Client Admins."
  />
  <ConfirmDialog
    :display="displayConfirmDelete"
    :disable="disableConfirmButton"
    @confirm="deleteBooking"
    @display="toggleDeleteBooking"
  />
</template>

<script>
import { ref, computed, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import BookingInfo from '@/components/BookingInfo'
import LocationInfo from '@/components/LocationInfo'
import AssignLab from '@/components/AssignLab'
import AssignTesters from '@/components/AssignTesters'
import UpdateTestees from '@/components/UpdateTestees'
import ConfirmDialog from '@/components/ConfirmDialog'
import SplitButton from 'primevue/splitbutton'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'
import Button from 'primevue/button'
import { useToast } from 'primevue/usetoast.js'
import { firestore, db } from '@/services/firebase'
import { useCreateBooking } from '@/composables/useCreateBooking'
import { useGetProjects } from '@/composables/useGetProjects'
import { getPermissions } from '@/helpers/permissions'
import {
  onBookingConfirmation,
  onBookingClose,
  patientConfirmation
} from '@/helpers/email'
import { getNewKey } from '@/helpers/firebase'
import { formatDate } from '@/helpers/date'
import { useDeleteDocument } from '@/composables/useDeleteDocument'
import { useHandleError } from '@/composables/useHandleError'
import { calculateTimes, calculateMinutes } from '@/helpers/patients'

export default {
  components: {
    BookingInfo,
    LocationInfo,
    AssignLab,
    AssignTesters,
    UpdateTestees,
    ConfirmDialog,
    Button,
    BaseBreadcrumb,
    SplitButton
  },
  setup() {
    const displayConfirmBooking = ref(false)
    const displayCompleteBooking = ref(false)
    const disableConfirmBooking = ref(false)
    const disableCompleteBooking = ref(false)
    const disabled = ref(false)
    const booking = ref({})
    const users = ref([])
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const toast = useToast()
    const disableForm = ref(true)
    const breadcrumbs = [
      { label: 'Bookings', to: '/requested-bookings' },
      { label: 'Booking Info' }
    ]
    const { locations, getLocations, testees, getTestees } = useCreateBooking({
      redirect: false
    })
    const {
      disableConfirmButton,
      displayConfirmDelete,
      deleteDocument,
      confirmDeleteDocument
    } = useDeleteDocument()
    const { handleError } = useHandleError()
    const {
      canConfirmBooking,
      canCloseBooking,
      canDeleteBooking,
      isClientAdminOnly
    } = getPermissions()
    const { projects, getProjects } = useGetProjects()
    let timeout

    async function getBooking() {
      const docRef = firestore.collection('bookings').doc(route.params.id)

      try {
        const doc = await docRef.get()

        if (doc.exists) {
          const result = doc.data()
          result.id = doc.id

          // redirect user if they have no permissions to see this booking
          if (isClientAdminOnly.value && result.project && result.project.id) {
            const user = store.getters.user
            const snapshot = await db
              .ref('projects/' + result.project.id)
              .once('value')

            if (snapshot.val()) {
              const project = snapshot.val()
              if (project.users.length) {
                const exists = project.users.find(row => row.id === user.id)

                if (!exists) router.push({ name: 'RequestedBookings' })
                else booking.value = result
              }
            }
          } else {
            booking.value = result
          }
        }
        // prevent strange behavior that focuses on last dropdown by delaying form editing ability
        await new Promise(done => (timeout = setTimeout(() => done(), 500)))
        disableForm.value = false
      } catch (error) {
        handleError(error)
      }
    }

    onUnmounted(() => {
      clearTimeout(timeout)
    })

    function getStatus(patients, collectors, pendingStatus) {
      let complete = 0
      let confirmed = 0
      let pending = 0
      let results = 0

      patients.forEach(row => {
        const { status, result } = row
        if (status === 'Pending') pending++
        if (status === 'Booking Confirmed') confirmed++
        if (status === 'Testing Complete') {
          complete++
          confirmed++
        }
        if (status === 'No Show') {
          complete++
          confirmed++
        }
        if ((result && result !== 'Pending') || status === 'No Show') results++
      })

      // if status is about to be set to Confirmed, check to see if it should go all the way to Testing Complete
      let status = pendingStatus ? pendingStatus : booking.value.status
      const all = patients.length

      switch (status) {
        case 'Closed':
          status = 'Closed'
          break
        case 'Results Complete':
          if (!collectors.length) status = 'Confirmed'
          if (complete === all && results !== all) status = 'Testing Complete'
          else if (complete !== all) status = 'Confirmed'
          break
        case 'Testing Complete':
          if (!collectors.length) status = 'Confirmed'
          if (complete === all && results === all) status = 'Results Complete'
          else if (complete !== all) status = 'Confirmed'
          break
        case 'Confirmed':
          if (!collectors.length) status = 'Confirmed'
          else if (complete === all && results === all)
            status = 'Results Complete'
          else if (complete === all) status = 'Testing Complete'
          break
        case 'Ready':
          if (!collectors.length) status = 'Requested'
          else if (pending) status = 'Requested'
          break
        case 'Requested':
          if (!collectors.length) status = 'Requested'
          else if (confirmed === all) status = 'Ready'
          break
        default:
          status = 'Requested'
      }
      return status
    }

    async function updateBooking(params, msg) {
      disableForm.value = true
      params.modified = new Date()

      await firestore
        .collection('bookings')
        .doc(booking.value.id)
        .set(params, { merge: true })
        .then(() => {
          getBooking()

          if (msg) {
            toast.add({
              severity: 'success',
              summary: 'Success Message',
              detail: msg,
              life: 3000
            })
          }
        })
    }

    function saveLab(lab) {
      updateBooking({ lab }, 'Lab Saved Successully')
    }

    function saveTesters(testers) {
      let testees = booking.value.testees
      let duration = booking.value.duration
      const status = getStatus(testees, testers)
      const testerIds = testers.map(row => row.id)

      // calculate patient times
      if (testers.length) {
        const datetime = booking.value.datetime.toDate()
        // book multiple patients at same time if more than 1 tester
        testees = calculateTimes({ datetime, testers, testees })
      }
      duration = calculateMinutes({ testers, testees })

      updateBooking(
        { testers, testerIds, status, testees, duration },
        'Collectors Updated'
      )
    }

    function saveTestees(testees) {
      const testers = booking.value.testers
      const datetime = booking.value.datetime.toDate()
      const status = getStatus(testees, testers)
      const duration = calculateMinutes({ testers, testees })
      const patients = calculateTimes({ datetime, testers, testees })

      updateBooking(
        {
          testees: patients,
          status,
          amount: testees.length,
          duration
        },
        'Patients Updated'
      )
    }

    function saveBooking(booking) {
      updateBooking(booking, 'Booking Saved Successully')
    }

    function saveLocation(location) {
      updateBooking(location, 'Location Saved Successully')
    }

    function addTestee(testee) {
      // if booking status is confirmed
      if (booking.value.status === 'Confirmed') {
        testee.status = 'Booking Confirmed'
        // send email to patient
        patientConfirmation(booking.value, testee)
      }

      if (!testee.id) {
        // add patient to autocomplete table
        const newPatientKey = getNewKey('patients')
        testee.dob = testee.dob ? formatDate({ date: testee.dob }) : ''
        db.ref('patients/' + newPatientKey)
          .set(testee)
          .then(() => {
            testee.id = newPatientKey
            booking.value.testees.push(testee)
            saveTestees(booking.value.testees)
          })
      } else {
        booking.value.testees.push(testee)
        saveTestees(booking.value.testees)
      }
    }

    function editTestee(testee) {
      testee.dob = formatDate({ date: testee.dob })
      const { id, ...patient } = testee
      if (id) {
        db.ref('patients/' + id)
          .set(patient)
          .then(() => {
            booking.value.testees = booking.value.testees.map(row => {
              if (row.id === id) return testee
              return row
            })
            saveTestees(booking.value.testees)
          })
      }
    }

    async function confirmBooking() {
      displayConfirmBooking.value = false

      const status = getStatus(
        booking.value.testees,
        booking.value.testers,
        'Confirmed'
      )

      const confirmed = store.getters.user
      confirmed.date = new Date()

      await updateBooking({ status, confirmed }, 'Booking Confirmed')

      // email client admin, patients and collectors
      onBookingConfirmation({ ...booking.value, status, confirmed })
    }

    async function completeBooking(comments) {
      displayCompleteBooking.value = false

      const completed = store.getters.user
      completed.date = new Date()

      await updateBooking(
        { status: 'Closed', completed, comments },
        'Booking Completed'
      )

      // send emails
      onBookingClose({
        ...booking.value,
        status: 'Closed',
        completed,
        comments
      })

      router.push({ name: 'RequestedBookings' })
    }

    function toggleConfirmBooking(value) {
      displayConfirmBooking.value = value
    }

    function toggleCompleteBooking(value) {
      displayCompleteBooking.value = value
    }

    function deleteBooking() {
      confirmDeleteDocument('bookings')
        .then(() => {
          router.push({ name: 'RequestedBookings' })
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Successfully deleted',
            life: 3000
          })
        })
        .catch(error => {
          handleError(error)
        })
    }

    function toggleDeleteBooking(value) {
      displayConfirmDelete.value = value
    }

    if (route.params.id) {
      getBooking()
      getProjects()
    }

    const isClosed = computed(() => {
      if (store.getters.isSuper) return false

      if (booking.value.status && booking.value.status !== 'Closed')
        return false

      return true
    })

    const reportType = ref('booking')

    const reportUrl = computed(() => {
      return `${process.env.VUE_APP_FUNCTIONS_URL}/pdf?url=${process.env.VUE_APP_URL}report/${reportType.value}/${booking.value.id}`
    })

    const reportClick = type => {
      if (type) reportType.value = type
      window.open(reportUrl.value, '_blank')
    }

    const findCommonElements = (arr1, arr2) => {
      return arr1.some(item => arr2.includes(item))
    }

    const bookingReport = ['Booking Admin', 'Client Admin', 'Super']
    const medicalReport = ['Medical Admin']
    const testerReport = ['Tester']

    const showReportLink = computed(() => {
      if (store.getters.user && store.getters.user.roles) {
        const roles = store.getters.user.roles
        const arr = bookingReport.concat(medicalReport, testerReport)
        if (findCommonElements(roles, arr)) return true
      }
      return false
    })

    watch(
      () => store.getters.user,
      () => {
        if (store.getters.user && store.getters.user.roles) {
          const roles = store.getters.user.roles
          if (findCommonElements(roles, medicalReport))
            reportType.value = 'medical'
          else if (findCommonElements(roles, bookingReport))
            reportType.value = 'booking'
          else if (findCommonElements(roles, testerReport))
            reportType.value = 'tester'
        }
      }
    )

    const openReportPreview = type => {
      const route = router.resolve({
        name: 'Report',
        params: { type },
        id: booking.value.id
      })
      window.open(route.href, '_blank')
    }

    let reportItems = [
      {
        label: 'Preview',
        icon: 'pi pi-search',
        command: () => openReportPreview(reportType.value)
      },
      {
        label: 'Download',
        icon: 'pi pi-download',
        command: () => reportClick()
      }
    ]

    if (store.getters.isSuper) {
      reportItems = [
        {
          label: 'Preview Medical',
          icon: 'pi pi-search',
          command: () => openReportPreview('medical')
        },
        {
          label: 'Preview Booking',
          icon: 'pi pi-search',
          command: () => openReportPreview('booking')
        },
        {
          label: 'Preview Tester',
          icon: 'pi pi-search',
          command: () => openReportPreview('tester')
        },
        {
          label: 'Preview Emails',
          icon: 'pi pi-search',
          command: () => openReportPreview('emails')
        },
        {
          label: 'Download Medical',
          icon: 'pi pi-download',
          command: () => reportClick('medical')
        },
        {
          label: 'Download Booking',
          icon: 'pi pi-download',
          command: () => reportClick('booking')
        },
        {
          label: 'Download Tester',
          icon: 'pi pi-download',
          command: () => reportClick('tester')
        },
        {
          label: 'Download Emails',
          icon: 'pi pi-download',
          command: () => reportClick('emails')
        }
      ]
    }

    const isConfirmed = computed(() => {
      const status = [
        'Confirmed',
        'Testing Complete',
        'Results Complete',
        'Closed'
      ]
      return status.includes(booking.value.status)
    })

    const canEditBookingTime = computed(() => {
      return !isConfirmed.value || store.getters.isSuper
    })

    return {
      displayConfirmBooking,
      displayCompleteBooking,
      disableConfirmBooking,
      disableCompleteBooking,
      disabled,
      booking,
      users,
      saveLab,
      saveTesters,
      saveTestees,
      saveBooking,
      saveLocation,
      addTestee,
      editTestee,
      confirm,
      disableForm,
      locations,
      testees,
      getLocations,
      getTestees,
      canConfirmBooking,
      canCloseBooking,
      confirmBooking,
      completeBooking,
      toggleConfirmBooking,
      toggleCompleteBooking,
      projects,
      displayConfirmDelete,
      deleteDocument,
      deleteBooking,
      toggleDeleteBooking,
      disableConfirmButton,
      canDeleteBooking,
      isClosed,
      breadcrumbs,
      reportUrl,
      reportClick,
      reportItems,
      showReportLink,
      canEditBookingTime
    }
  }
}
</script>
