<template>
  <p>
    <Button
      v-if="canAddPatients && save"
      @click="createPatient = true"
      label="Add Patient"
    />
  </p>
  <p v-if="canUpdatePatientStatus && save">
    <Dropdown
      v-model="updateAllStatus"
      :options="statuses"
      placeholder="Status"
    />
    &nbsp;
    <Button
      @click="updateAllTestees('status')"
      :disabled="disabledStatus"
      label="Update All"
    />
  </p>
  <p v-if="canUpdatePatientResult && save">
    <Dropdown
      v-model="updateAllResult"
      :options="results"
      placeholder="Result"
    />
    &nbsp;
    <Button
      @click="updateAllTestees('result')"
      :disabled="disabledResult"
      label="Update All"
    />
  </p>
  <DataTable :value="booking.testees" class="p-datatable-responsive">
    <Column
      v-for="{ field, header } in columns"
      :key="field"
      :field="field"
      :header="header"
    >
      <template #body="slotProps">
        <span class="p-column-title">{{ header }}</span>
        <UpdateDropdown
          v-if="header === 'Status' && canUpdatePatientStatus && save"
          :id="slotProps.data.id"
          :options="statuses"
          :populateWith="slotProps.data.status"
          field="Status"
          @selected="saveTestee"
          :disabled="disabled"
        />
        <UpdateDropdown
          v-else-if="header === 'Result' && canUpdatePatientResult && save"
          :id="slotProps.data.id"
          :options="results"
          :populateWith="slotProps.data.result"
          field="Result"
          @selected="saveTestee"
          :disabled="disabled"
        />
        <div v-else-if="header === 'Actions' && canAddPatients && save">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-text p-button-sm"
            @click="editPatient(slotProps.data)"
          />
          <Button
            icon="pi pi-times"
            class="p-button-rounded p-button-danger p-button-text p-button-sm"
            @click="deletePatient(slotProps.data.id)"
          />
        </div>
        <span v-else-if="header === 'Result' && !canUpdatePatientResult">
          <em>Hidden</em>
        </span>
        <span v-else-if="header === 'Time'">
          {{ getTime(slotProps.data[field]) }}
        </span>
        <span v-else>{{ slotProps.data[field] }}</span>
      </template>
    </Column>
  </DataTable>
  <Dialog
    header="Add Patient"
    v-model:visible="createPatient"
    :maximizable="true"
    :dismissableMask="true"
    :modal="true"
  >
    <TesteeForm
      :testees="testees"
      @testees="getTestees"
      @submitted="addTestee"
      :showButton="true"
    />
  </Dialog>
  <Dialog
    header="Edit Patient"
    v-model:visible="updatePatient"
    :maximizable="true"
    :dismissableMask="true"
    :modal="true"
  >
    <TesteeForm
      :testees="testees"
      @testees="getTestees"
      @submitted="editTestee"
      :populateWith="patient"
      :showButton="true"
    />
  </Dialog>
  <ConfirmDialog
    :display="displayConfirmDelete"
    :disable="disableConfirmButton"
    @confirm="confirmDeletePatient"
    @display="displayDeleteDialog"
    :message="'Are you sure you want to delete patient?'"
  />
</template>

<script>
import { ref, computed, watchEffect } from 'vue'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'
import UpdateDropdown from '@/components/UpdateDropdown'
import TesteeForm from '@/components/TesteeForm.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import { getPermissions } from '@/helpers/permissions'
import moment from 'moment'

export default {
  components: {
    Dropdown,
    Button,
    DataTable,
    Column,
    Dialog,
    UpdateDropdown,
    TesteeForm,
    ConfirmDialog
  },
  props: {
    booking: {
      type: Object,
      default: new Object()
    },
    disabled: {
      type: Boolean,
      required: true
    },
    testees: {
      type: Array,
      default() {
        return []
      }
    },
    save: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: true
    },
    showResults: {
      type: Boolean,
      default: true
    }
  },
  emits: ['save', 'add', 'edit', 'testees'],
  setup(props, { emit }) {
    const createPatient = ref(false)
    const updatePatient = ref(false)
    const updateAllStatus = ref(null)
    const updateAllResult = ref(null)
    const statuses = [
      'Pending',
      'Booking Confirmed',
      'Testing Complete',
      'No Show'
    ]
    const results = ref(['Pending', 'Negative', 'Positive'])
    const {
      canAddPatients,
      canUpdatePatientResult,
      canUpdatePatientStatus
    } = getPermissions()

    const columns = ref([
      { field: 'datetime', header: 'Time' },
      { field: 'name', header: 'Name' },
      { field: 'email', header: 'Email' },
      { field: 'status', header: 'Status' }
    ])

    const patients = ref([])

    watchEffect(() => {
      if ('testees' in props.booking) {
        // combine first and last name into one column
        patients.value = props.booking.testees.map(row => {
          row.name = `${row.firstName} ${row.lastName}`
          return row
        })

        // if show results
        if (props.showResults)
          columns.value[4] = {
            field: 'result',
            header: 'Result'
          }

        // if user has permissions, allow them to delete
        if (canAddPatients.value && props.showActions)
          columns.value[5] = {
            field: 'actions',
            header: 'Actions'
          }
      }
    })

    const disabledStatus = computed(() => {
      if (updateAllStatus.value) return false
      return true
    })
    const disabledResult = computed(() => {
      if (updateAllResult.value) return false
      return true
    })

    function saveTestee(params) {
      const field = params.field.toLowerCase()
      // identify which testee to updates
      // return entire testee array to replace existing array
      const testees = props.booking.testees.map(row => {
        if (row.id === params.id) {
          row[field] = params.selected
          if (field === 'status' && params.selected !== 'Testing Complete')
            row.result = null
          return row
        }
        return row
      })

      emit('save', testees) // field types = status or result
    }

    function updateAllTestees(type) {
      // update all testees
      // type = status or result
      // return entire testee array to replace existing array
      const testees = props.booking.testees.map(row => {
        if (type === 'status') row[type] = updateAllStatus.value
        else if (type === 'result') row[type] = updateAllResult.value
        return row
      })

      emit('save', testees)

      // clear input after button click
      if (type === 'status') updateAllStatus.value = null
      else if (type === 'result') updateAllResult.value = null
    }

    function addTestee(testee) {
      createPatient.value = false
      emit('add', testee)
    }

    function editTestee(testee) {
      updatePatient.value = false
      delete testee.name
      emit('edit', testee)
    }

    const disableConfirmButton = ref(false)
    const displayConfirmDelete = ref(false)
    const patientId = ref(null)

    function displayDeleteDialog(value) {
      displayConfirmDelete.value = value
    }

    function deletePatient(id) {
      displayConfirmDelete.value = true
      patientId.value = id
    }

    function confirmDeletePatient() {
      displayConfirmDelete.value = false
      const testees = props.booking.testees.filter(row => {
        if (row.id !== patientId.value) return true
      })
      emit('save', testees)
    }

    const patient = ref({})

    function editPatient(row) {
      patient.value = { ...row }
      updatePatient.value = true
    }

    function getTestees() {
      emit('testees')
    }

    function getTime(val) {
      if (val) return moment(val.toDate()).format('h:mm a')
      return ''
    }

    return {
      createPatient,
      updatePatient,
      updateAllStatus,
      updateAllResult,
      statuses,
      results,
      saveTestee,
      updateAllTestees,
      addTestee,
      editTestee,
      getTestees,
      disabledStatus,
      disabledResult,
      canAddPatients,
      canUpdatePatientResult,
      canUpdatePatientStatus,
      columns,
      patients,
      patientId,
      deletePatient,
      confirmDeletePatient,
      disableConfirmButton,
      displayConfirmDelete,
      displayDeleteDialog,
      editPatient,
      patient,
      getTime
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/table.scss';
.p-datatable-responsive {
  ::v-deep(.p-inputtext) {
    font-size: 0.875rem;
  }
  ::v-deep(.p-datatable-tbody) {
    td {
      overflow: visible;
    }
  }
}
@media screen and (min-width: 770px) {
  .p-dialog-mask {
    ::v-deep(.p-dialog) {
      width: 456px;
    }
  }
}
</style>
