<template>
  <div v-if="!booking.lab || editMode" class="p-input-filled">
    <form v-if="canUpdateLab && save" @submit.prevent="saveLab">
      <div class="p-grid">
        <div class="p-col-12 p-sm-6">
          <div class="p-field">
            <Dropdown
              v-model="lab"
              :options="labs"
              placeholder="Lab Name"
              :disabled="!canUpdateLab"
              :class="{ 'p-invalid': validation.lab.$error }"
            />
            <BaseValidationErrors
              :field="validation.lab"
              :rules="['required']"
            />
          </div>
          <Button
            type="submit"
            class="save-lab p-button-sm p-button-outlined"
            label="Save Lab"
            icon="pi pi-save"
            :disabled="validation.$invalid"
          />
          <Button
            v-if="booking.lab"
            class="p-ml-2 cancel-lab p-button-sm p-button-outlined p-button-danger"
            label="Cancel"
            icon="pi pi-times"
            @click="toggle"
          />
        </div>
      </div>
    </form>
    <div v-else class="p-my-3"><em>Lab not assigned</em></div>
  </div>
  <ul v-else>
    <li>{{ booking.lab }}<br /></li>
    <li>
      <Button
        v-if="canUpdateLab && save"
        @click="onEditModeClick"
        class="edit-lab p-button-sm p-button-outlined"
        label="Edit Lab"
        icon="pi pi-pencil"
      />
    </li>
  </ul>
</template>

<script>
import { ref, watchEffect } from 'vue'
import Button from 'primevue/button'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'
import { getPermissions } from '@/helpers/permissions'
import { labs } from '@/helpers/constants'
import Dropdown from 'primevue/dropdown'

export default {
  components: { Button, Dropdown, BaseValidationErrors },
  props: {
    booking: {
      type: Object,
      default: new Object()
    },
    save: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submitted'],
  setup(props, { emit }) {
    const editMode = ref(false)
    const lab = ref(null)
    const { canUpdateLab } = getPermissions()

    const rules = {
      lab: { required }
    }
    const validation = useVuelidate(rules, { lab })

    watchEffect(() => {
      if (props.booking.lab) lab.value = props.booking.lab
    })

    function toggle() {
      editMode.value = !editMode.value
    }

    function onEditModeClick() {
      toggle()
    }

    function saveLab() {
      validation.value.$touch()
      if (!validation.value.$invalid) {
        emit('submitted', lab.value)
        editMode.value = false
      }
    }

    return {
      editMode,
      lab,
      labs,
      onEditModeClick,
      saveLab,
      toggle,
      validation,
      canUpdateLab
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/forms.scss';
ul {
  margin: 0 0 1rem;
  padding-left: 0;
  list-style: none;
  li {
    margin-bottom: 0.5rem;
  }
}
</style>
