<template>
  <div v-if="editMode" class="p-grid p-input-filled">
    <div class="p-col-12 p-sm-6">
      <form v-if="canUpdateBooking" @submit.prevent="saveLocation">
        <div class="p-field">
          <AutoComplete
            :suggestions="filteredLocations"
            @complete="searchLocations($event)"
            @item-select="selectLocation($event)"
            placeholder="Name/Description"
            field="name"
            class="testing-name"
            v-model="formData.location.name"
            @focus="!locations.length ? $emit('locations') : null"
            @blur="validation.formData.location.name.$touch()"
            :class="{ 'p-invalid': validation.formData.location.name.$error }"
          />
          <BaseValidationErrors
            :field="validation.formData.location.name"
            :rules="['required']"
          />
        </div>
        <div class="p-field">
          <InputText
            placeholder="Street Address"
            type="text"
            class="testing-address"
            v-model="formData.location.address"
            @blur="validation.formData.location.address.$touch()"
            :class="{
              'p-invalid': validation.formData.location.address.$error
            }"
          />
          <BaseValidationErrors
            :field="validation.formData.location.address"
            :rules="['required']"
          />
        </div>
        <div class="p-field">
          <InputText
            placeholder="City"
            type="text"
            class="testing-city"
            v-model="formData.location.city"
            @blur="validation.formData.location.city.$touch()"
            :class="{ 'p-invalid': validation.formData.location.city.$error }"
          />
          <BaseValidationErrors
            :field="validation.formData.location.city"
            :rules="['required']"
          />
        </div>
        <div class="p-field">
          <InputText
            placeholder="Province"
            type="text"
            class="testing-province"
            v-model="formData.location.province"
            @blur="validation.formData.location.province.$touch()"
            :class="{
              'p-invalid': validation.formData.location.province.$error
            }"
          />
          <BaseValidationErrors
            :field="validation.formData.location.province"
            :rules="['required']"
          />
        </div>
        <div class="p-field">
          <InputText
            placeholder="Postal Code"
            type="text"
            class="testing-postal-code"
            v-model="formData.location.postalCode"
            @blur="validation.formData.location.postalCode.$touch()"
            :class="{
              'p-invalid': validation.formData.location.postalCode.$error
            }"
          />
          <BaseValidationErrors
            :field="validation.formData.location.postalCode"
            :rules="['required']"
          />
        </div>
        <Button
          type="submit"
          class="save-location p-button-sm p-button-outlined"
          label="Save Location"
          icon="pi pi-save"
          :disabled="validation.$invalid"
        />
        <Button
          class="p-ml-2 cancel-location p-button-sm p-button-outlined p-button-danger"
          label="Cancel"
          icon="pi pi-times"
          @click="toggle"
        />
      </form>
    </div>
  </div>
  <ul v-else-if="location">
    <li v-if="location.name">{{ location.name }}</li>
    <li v-if="location.address">{{ location.address }}</li>
    <li v-if="location.city || location.province || location.postalCode">
      {{ location.city }}, {{ location.province }}
      {{ location.postalCode }}
    </li>
    <li v-if="save">
      <Button
        v-if="canUpdateBooking"
        @click="onEditModeClick"
        class="edit-location p-button-sm p-button-outlined"
        label="Edit Location"
        icon="pi pi-pencil"
      />
    </li>
  </ul>
</template>

<script>
import { ref } from 'vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import AutoComplete from 'primevue/autocomplete'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'
import { getPermissions } from '@/helpers/permissions'

export default {
  components: { Button, InputText, AutoComplete, BaseValidationErrors },
  props: {
    location: {
      type: Object,
      default: new Object()
    },
    locations: {
      type: Array,
      default() {
        return []
      }
    },
    save: {
      type: Boolean,
      default: false
    }
  },
  emits: ['locations'],
  setup(props, { emit }) {
    const { canUpdateBooking } = getPermissions()
    const editMode = ref(false)
    const formData = ref({
      location: {
        id: null,
        name: '',
        address: '',
        city: '',
        province: '',
        postalCode: ''
      }
    })

    const rules = {
      formData: {
        location: {
          name: { required },
          address: { required },
          city: { required },
          province: { required },
          postalCode: { required }
        }
      }
    }
    const validation = useVuelidate(rules, { formData })

    const filteredLocations = ref([])

    function searchLocations(event) {
      if (!event.query.trim().length) {
        filteredLocations.value = [...props.locations]
      } else {
        filteredLocations.value = props.locations.filter(location => {
          if (!location.name) return false
          return location.name
            .toLowerCase()
            .startsWith(event.query.toLowerCase())
        })
      }
    }

    function selectLocation(event) {
      formData.value.location.id = event.value.id
      formData.value.location.name = event.value.name
      formData.value.location.address = event.value.address
      formData.value.location.city = event.value.city
      formData.value.location.province = event.value.province
      formData.value.location.postalCode = event.value.postalCode
    }

    function toggle() {
      editMode.value = !editMode.value
    }

    function onEditModeClick() {
      const { location } = props
      formData.value = { location }
      toggle()
    }

    function saveLocation() {
      validation.value.$touch()
      if (!validation.value.$invalid) {
        emit('submitted', formData.value)
        toggle()
      }
    }

    return {
      editMode,
      formData,
      filteredLocations,
      searchLocations,
      selectLocation,
      onEditModeClick,
      saveLocation,
      toggle,
      validation,
      canUpdateBooking
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/forms.scss';
ul {
  margin: 0 0 1rem;
  padding-left: 0;
  list-style: none;
  li {
    margin-bottom: 0.25rem;
  }
}
.edit-location {
  margin-top: 0.25rem;
}
</style>
