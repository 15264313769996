<template>
  <div class="p-col-12 p-sm-6" v-if="canAssignCollectors && save">
    <form @submit.prevent="onSubmit" class="p-grid p-input-filled">
      <AutoComplete
        :multiple="true"
        v-model="testers"
        :suggestions="filteredUsers"
        @complete="searchUsers($event)"
        placeholder="Email"
        field="email"
        :dropdown="true"
        @item-select="onSubmit"
      />
    </form>
  </div>
  <ul v-if="booking.testers && booking.testers.length" class="p-pl-4">
    <li v-for="tester in booking.testers" :key="tester.id">
      <div class="p-d-flex p-ai-center">
        {{ tester.email }}
        <Button
          v-if="canAssignCollectors && save"
          icon="pi pi-times"
          class="p-button-rounded p-button-danger p-button-text p-ml-1"
          @click="removeTester(tester.id)"
        />
      </div>
    </li>
  </ul>
  <div v-else class="p-my-3"><em>No Collectors Assigned</em></div>
</template>

<script>
import { ref, computed } from 'vue'
import AutoComplete from 'primevue/autocomplete'
import Button from 'primevue/button'
import { getPermissions } from '@/helpers/permissions'
import { firestore } from '@/services/firebase'

export default {
  components: { AutoComplete, Button },
  props: {
    booking: {
      type: Object,
      default: new Object()
    },
    save: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submitted'],
  setup(props, { emit }) {
    const testers = ref([])
    const users = ref([])
    const filteredUsers = ref([])
    const { canAssignCollectors } = getPermissions()

    const disabled = computed(() => {
      if (testers.value.length) return false
      return true
    })

    const availableUsers = computed(() => {
      // check to see which users have already been assigned as testers for this booking
      const usersUnavailable = props.booking.testers.map(row => row.id)

      // filter out unavailable users
      return users.value.filter(row => {
        return !usersUnavailable.includes(row.id)
      })
    })

    async function searchUsers(event) {
      // only fetch users once autocomplete field is accessed
      // moved query inside component because emitting doesn't return a Promise
      if (!users.value.length) {
        const usersList = []
        const querySnapshot = await firestore
          .collection('users')
          .where('active', '==', true)
          .where('roles', 'array-contains', 'Tester')
          .get()

        querySnapshot.forEach(doc => {
          const user = doc.data()
          user.id = doc.id
          usersList.push(user)
        })
        users.value = usersList
      }

      if (!event.query.trim().length) {
        filteredUsers.value = [...availableUsers.value]
      } else {
        filteredUsers.value = availableUsers.value.filter(user => {
          return user.email.toLowerCase().startsWith(event.query.toLowerCase())
        })
      }
    }

    function removeTester(testerId) {
      const users = props.booking.testers.filter(tester => {
        if (tester.id !== testerId) return true
      })
      emit('submitted', users)
    }

    function onSubmit() {
      const users = props.booking.testers
      testers.value.forEach(row => {
        users.push(row)
      })
      emit('submitted', users)
      testers.value = [] // clear text field
    }

    return {
      testers,
      filteredUsers,
      searchUsers,
      removeTester,
      onSubmit,
      disabled,
      canAssignCollectors
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/forms.scss';
</style>
